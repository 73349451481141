import { BaseReportStore2 } from '../../lib/stores/BaseReportStore2';
import { yesterdayYmd, dateFormat } from '../../lib/utils';
import moment from 'moment';

export const defaultFilterParams = {
    app_id: undefined,
    app_name: undefined,
    domain: undefined,
    number_pool_name: undefined,
    date_from: moment().day(-6).format(dateFormat),
    date_till: yesterdayYmd,
    leader_full_name: undefined,
    manager_full_name: undefined,
};

export default class Ct3RatioStatisticsStore extends BaseReportStore2 {
    constructor(props) {
        super(props);

        this.method = 'get.ct3_ratio_statistics';
        this.defaultReportParams.filterParams = { ...defaultFilterParams };
        this.reportParams.filterParams = { ...defaultFilterParams };
        this.defaultTablePagination.withoutTotal = true;
    }
}
