export const pluralizeRu = (val, pls) => {
    pls = pls || {singular: 'запись', plural: 'записей', plural234: 'записи'};
    if (val % 100 > 10 && val % 100 < 20) {
        return pls.plural;
    } else {
        switch (val % 10) {
            case 1:
                return pls.singular;
            case 2:
            case 3:
            case 4:
                return pls.plural234;
            default:
                return pls.plural;
        }
    }
};

export const unitsRu = {
    year: {singular: 'год', plural: 'лет', plural234: 'года'},
    mon: {singular: 'месяц', plural: 'месяцев', plural234: 'месяца'},
    day: {singular: 'день', plural: 'дней', plural234: 'дня'},
    client: {singular: 'клиент', plural: 'клиентов', plural234: 'клиента'},
};
