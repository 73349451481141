import { decorate, observable, action, computed } from 'mobx';
import httpRpc from '../../../../lib/api';
import { baseUrl } from '../../../../lib/api';

class NumbersManyStore {
    loading = false;
    data = {
        file_name: '',
        file: '',
        failed: null,
        succeed: null,
        file_id: '',
    };
    modalType = undefined;

    get paramsUpload() {
        return {
            file_name: this.data.file_name,
            file: this.data.file,
        };
    }

    get requestUpload() {
        return {
            failed: this.data.failed,
            succeed: this.data.succeed,
            file_id: this.data.file_id,
        };
    }

    setModalType = type => {
        this.modalType = type;
    }

    setParamsUpload = ({ file_name, file }) => {
        this.data = { ...this.data, file_name, file };
    }

    onClearData = () => {
        this.data = {
            file_name: '',
            file: '',
            failed: null,
            succeed: null,
            file_id: '',
        };
    }

    fetchUpload = async (app_id) => {
        try {
            this.loading = true;
            const params = { app_id, ...this.paramsUpload };
            const response = await httpRpc('upload.virtual_numbers', params);
            this.data.failed = response.failed;
            this.data.succeed = response.succeed;
            this.data.file_id = response.file_id;
        } catch(e) {
            return Promise.reject(e);
        } finally {
            this.loading = false;
        }
    }

    exportFailedNumbers = async (app_id) => {
        try {
            const getFileId = this.requestUpload;
            const params = { export: 1, app_id, file_id: getFileId.file_id };
            const res = await httpRpc('get.virtual_number_upload_report', params);
            window.open(baseUrl + res.url.slice(1), '_blank');
        } catch (e) {
            return Promise.reject(e);
        }
     }
}

decorate(NumbersManyStore, {
    onClearData: action,
    data: observable,
    loading: observable,
    modalType: observable,
    paramsUpload: computed,
    requestUpload: computed,
    setModalType: action,
    setParamsUpload: action,
});

export default NumbersManyStore;
